import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { type Message, ChatLine, LoadingChatLine } from './ChatLine';
import { useApp, useAuth } from '../../hooks';
import { mapMatrixData, getAllThreadsService, getThreadData, updateCollaboration, checkIfThreadIsBusy, setCustomThreadName, deleteThreadService, favouriteThreadService } from '../../services/chat.service';
import { getChatAnalyticsData } from '../../services/category.service';
import './Chat.css';
import axiosApiInstance from '../../services/request';
import CustomMentionInput from './CustomMentionInput';
import Pusher from 'pusher-js';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, ListSubheader, Menu, Modal, Select, TextField, Tooltip, useMediaQuery } from '@mui/material';
import { createThreadDatabaseFromFileService, get_upload_url_for_thread } from '../../services/databases.service';
import { v4 as uuidv4 } from 'uuid';
import ChatConfiguration from './ConfigurationPage';
import { Box, Button, MenuItem, Select as MuiSelect, InputLabel, FormControl, Typography, Slider, IconButton, Container, Grid, Paper, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getIcon } from "../../components/utils";
import SettingsPage from '../../pages/SettingsPage';
import { DarkModeToggle } from '../../App';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {FavoriteBorderOutlined} from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import Fade from "@mui/material/Fade";
import GroupIcon from '@mui/icons-material/Group';
import ChatCategoryMenu from '../../layouts/components/ChatCategoryMenu';
import AdminMenu from '../../layouts/components/AdminMenu';
import React from 'react';
import { AccountMenu } from './AccountMenu';
import DeleteIcon from '@mui/icons-material/Delete';


const VideoDrawer = ({ isOpen, onClose, videos }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
  };

  if (!videos || videos.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
    <Grid container alignItems="center" justifyContent="space-between" width="100%">
      <Grid item xs={2} sm={1} display="flex" justifyContent="center">
        <IconButton onClick={handlePrev} sx={{ backgroundColor: '#e0e0e0', '&:hover': { backgroundColor: '#d0d0d0' } }}>
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      <Grid item xs={8} sm={10} display="flex" justifyContent="center">
        <Box width="100%" maxWidth="600px" height="30vh" display="flex" justifyContent="center" alignItems="center">
          <video controls style={{ maxWidth: '100%', height: '30vh' }}>
            <source src={videos[currentIndex]['video_url']} type="video/mp4" />
            <p>
              Your browser doesn't support HTML video. Here is a
              <a href={videos[currentIndex]['video_url']}>link to the video</a> instead.
            </p>
          </video>
        </Box>
      </Grid>
      <Grid item xs={2} sm={1} display="flex" justifyContent="center">
        <IconButton onClick={handleNext} sx={{ backgroundColor: '#e0e0e0', '&:hover': { backgroundColor: '#d0d0d0' } }}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  </Box>
  );
};

export const initialMessages: Message[] = [
  {
    who: 'bot',
    message: 'Hi! I’m DatalinkerAI. Ask me anything!',
    spents: {},
    messageId: '',
    threadId: '',
    username: undefined,
    userId: undefined,
    createdAt: Date.now().toString()
  },
];

const pusher = new Pusher('c196869b41722096c3ce', {
  cluster: 'eu',
});

const InputMessage = ({ input, setInput, sendMessage, selectedChatCategory, loading, isCollaborativeThread, isThreadOwner }: any) => {
  const [selectedMention, setSelectedMention] = useState(null);
  const { state } : any = useApp();
  const { zapierActions } : any = state;
  return (
    <Box width="100%" display="flex" flexDirection="row" alignItems="center" >
      <Box width="100%">
        <CustomMentionInput
          zapierActions={zapierActions}
          value={input}
          onChange={(newValue) => setInput(newValue)}
          onMentionSelect={(mention) => setSelectedMention(mention[0])}
          placeholder={`Ask DatalinkerAI about ${selectedChatCategory?.category_name}`}
          sendMessage={sendMessage}
          loading={loading}
          isCollaborativeThread={isCollaborativeThread}
          isThreadOwner={isThreadOwner}
          organisation_id={state.selectedOrganisation._id}
        />
      </Box>
    </Box>
  );
};

export function Chat() {
  const { state, dispatch } : any = useApp();
  const { selectedChatCategory, selectedOrganisation, spentMatrix, generalThreads }: any  = state;
  const { threadIdParam, categoryIdParam, orgId }: any  = useParams();
  const [messages, setMessages]: any = useState<Message[]>(initialMessages);
  const [input, setInput]: any  = useState('');
  const [loading, setLoading]: any  = useState(false);
  const [selectedThread, setSelectedThread]: any  = useState(null);
  const [threads, setThreads]: any  = useState([]);
  const [isLoadingThread, setIsLoadingThread]: any  = useState(false);
  const [selectedModel, setSelectedModel]: any  = useState('gpt-4o');
  const [isCollaborativeThread, setIsCollaborativeThread]: any  = useState(false);
  const { auth, setAuth }: any  = useAuth();
  const [k_value, setKValue]: any  = useState(10);
  const [isDrawerOpen, setIsDrawerOpen]: any  = useState(false);
  const navigate: any  = useNavigate();
  const theme = useTheme();
  const [profilePhoto, setProfilePhoto] = useState('');
  const [openLeftDrawer, setLeftDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const orgOpenMenu = Boolean(anchorEl);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editThreadName, setEditThreadName] = useState(' ');
  const [selectedEditThreadId, setSelectedEditThreadId] = useState(null);
  const [isThreadDropdownOpen, setThreadIsDropdownOpen] = useState(false);
  const [editDialogError, setEditDialogError] = useState('');

  // Delete thread dialog
  const [deleteThreadDialogOpenState, setDeleteThreadDialogOpenState] = useState(false);
  const [threadToDelete, setThreadToDelete]: any = useState(null);

   

  
  

  useEffect(() => {
    if (selectedOrganisation?._id) {
      console.log("state.selectedOrganisation" ,selectedOrganisation);
      const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id);
      if ((role === 'owner' || role === 'admin') && (selectedOrganisation?.subscription_status !== 'active')) {
        navigate('/organisation/billing');
        dispatch({ type: "SET_NOTIFICATION", payload: { show: true, title: 'Subscription', message: 'Your subscription is currently inactive. Please check your account settings.', type: 'error' } });
      }
    }
    if (!auth.user.email_verified) {
      navigate("/verify-email");
    }
    if (categoryIdParam) {
      let response = getAllThreadsService(categoryIdParam);
      response.then((res: any ) => {
        if (threadIdParam && !res.find((thread: any ) => thread.thread_id === threadIdParam)) {
          const new_thread: any  = { thread_id: threadIdParam, thread_name: 'New Thread', created_at: Date.now(), user_id : auth.user.uid };
          res = res.map((thread: any ) => ({
            ...thread,
            created_at: new Date(thread.created_at.$date).getTime()
          }));
          res = [...res, new_thread];
          setThreads(res);
          setSelectedThread(new_thread);
          setIsCollaborativeThread(false);
          setMessages(initialMessages);
          dispatch({ type: 'SET_THREAD_ID', payload: new_thread.thread_id });
        } else {
          const selectedThread: any  = res.find((thread: any ) => thread.thread_id === threadIdParam);
          setThreads(res);
          setSelectedThread(selectedThread);
          setIsLoadingThread(true);
          const threadData: any  = getThreadData(threadIdParam);
          threadData.then((response: any ) => {
            if (response.status === 200) {
              return response.data;
            } else if (response.status === 401) {
              navigate(`/organisation/${selectedOrganisation._id}/category/${selectedChatCategory.category_id}/thread/${uuid()}`);
              return response;
            } else if (response.status === 400) {
              return response;
            }
          })
            .then((data: any ) => {
              const selectedThread: any  = res.find((thread: any ) => thread.thread_id === threadIdParam);
              setSelectedThread(selectedThread);
              if (data.success === 0) {
                setMessages(initialMessages);
                dispatch({ type: 'SET_THREAD_ID', payload: threadIdParam });
              } else {
                const transformedMessages: any  = data.data.response.map((item: any ) => ({
                  who: item.message_sender === 'bot' ? 'bot' : 'user',
                  message: item.message,
                  spents: {},
                  messageId: item.message_id,
                  threadId: threadIdParam,
                  userId: item.user_id,
                  username: item.user_name,
                  createdAt: item.created_at
                }));
                setMessages(transformedMessages);
                dispatch({ type: 'SET_THREAD_ID', payload: threadIdParam });
                if (selectedThread?.collaboration) {
                  setIsCollaborativeThread(selectedThread.collaboration);
                  createCollaborativeThread(true);
                } else {
                  setIsCollaborativeThread(false);
                  createCollaborativeThread(false);
                }
                updateThreadName(threadIdParam);
              }
              setIsLoadingThread(false);
            })
            .catch((error: any) => { console.error('Error:', error); setIsLoadingThread(true); });
        }

        if(categoryIdParam === 'GENERAL') {
          setIsDrawerOpen(false);
        }
        
      });
    }
    handleLeftDrawerClose();
    if (auth?.user) {
      const image = getIcon(auth.user.email.split('')[0].toUpperCase());
      setProfilePhoto(image);
    }

    console.log("Chat Category", categoryIdParam);
  }, [categoryIdParam, threadIdParam, auth]);

  
  const previousThreadRef = useRef();

  useEffect(() => {
    previousThreadRef.current = selectedThread;
  });

  const previousThread: any = previousThreadRef.current;

  useEffect(() => {
   

    if (selectedThread?.thread_id !== previousThread?.thread_id) {
      if(previousThread?.thread_id) {
          setIsCollaborativeThread(false);
          const channel: any  = pusher.channel(previousThread?.thread_id);
          if (channel) {
            channel.unbind('llm-response', handleResponse);
            channel.unbind('llm-question', handleQuestion);
            channel.unbind('llm-collaboration', handleCollaboration);
            pusher.unsubscribe(previousThread?.thread_id);
          }
      }
      previousThreadRef.current = selectedThread;
    }
  }, [selectedThread]);

  useEffect(() => {
    if (isDrawerOpen && !selectedChatCategory?.videos)
      setIsDrawerOpen(false);

    if (selectedChatCategory?.videos) {
      setIsDrawerOpen(true);
    }
  }, [selectedChatCategory]);

  function RedirectToNewThread() {
    console.log('Redirecting to new thread');
    const navigate = useNavigate();
    const { threadIdParam, categoryIdParam, orgId }: any  = useParams();
    const { state, dispatch }: any  = useApp();
    const { userChatCategories, organisations }: any  = state;

    useEffect(() => {
      if (categoryIdParam === '1' || categoryIdParam === 'undefined') {
        console.log('Entering here', selectedChatCategory?.category_id);
        if (orgId && orgId !== state.selectedOrganisation._id) {
          const organisation: any  = organisations.find((org: any ) => org._id === orgId);
          if (organisation) {
            dispatch({ type: 'SET_ORGANISATION', payload: organisation });
            dispatch({ type: 'SET_ACTIVE_ORGANISATION', payload: organisation });
            navigate(`/organisation/${orgId}/category/1/thread/1`);
          } else {
            handleNewGeneralThread();
          }
        } else if (!selectedChatCategory?.category_id) {
          console.log('Entering here', selectedChatCategory);
          handleNewGeneralThread();
        } else {
          navigate(`/organisation/${selectedOrganisation._id}/category/${selectedChatCategory?.category_id}/thread/${uuid()}`);
        }
      } else if (threadIdParam === '1') {
        navigate(`/thread/${uuid()}`);
      } else if (orgId && orgId !== state.selectedOrganisation._id) {
        const organisation: any  = organisations.find((org: any ) => org._id === orgId);
        if (organisation) {
          dispatch({ type: 'SET_ORGANISATION', payload: organisation });
          dispatch({ type: 'SET_ACTIVE_ORGANISATION', payload: organisation });
        }
      } else if (categoryIdParam && categoryIdParam !== state.selectedChatCategory?.category_id) {
        const category = userChatCategories.find((category: any ) => category.category_id === categoryIdParam);
        if (category) {
          dispatch({ type: 'SET_USER_ACTIVE_CHAT_CATEGORY', payload: category });
        }
      }
    }, [orgId, navigate, categoryIdParam, threadIdParam]);

    return null;
  }

  useEffect(() => {
    const messageContainerDiv = document.getElementById("message-container-div");
    if (messageContainerDiv) {
      console.log('Scrolling to bottom');
      messageContainerDiv.scrollTop = messageContainerDiv.scrollHeight;
    }
  }, [messages]);

  const updateThreadName = (threadId: any ) => {
    let response = getAllThreadsService(categoryIdParam);
    response.then((res) => {
      const selectedThread: any  = res.find((thread: any ) => thread.thread_id === threadId);
      res = res.map((thread: any ) => ({
        ...thread,
        created_at: new Date(thread.created_at.$date).getTime()
      }));
      res.sort((a: any , b: any ) => b.created_at - a.created_at);
      setThreads(res);
      setSelectedThread(selectedThread);
      if (selectedChatCategory?.category_id === 'GENERAL') {
        dispatch({ type: 'SET_GENERAL_THREADS', payload: res });
      }
    });
  };

  const handleCreateNewThread = () => {
    navigate(`/organisation/${selectedOrganisation._id}/category/${selectedChatCategory?.category_id}/thread/${uuid()}`);
  };

  const handleThreadSelect = (event: any ) => {
    navigate(`/organisation/${selectedOrganisation._id}/category/${selectedChatCategory?.category_id}/thread/${event.target.value}`);
  };

  function setMatrixData() {
    getChatAnalyticsData(threadIdParam, selectedOrganisation._id).then((res: any) => {
      const matrixData = mapMatrixData(res);
      dispatch({
        type: 'SET_MATRIX',
        payload: matrixData
      });
    });
  }

  function handleResponse(data: any ) {
    if (!loading) setLoading(false);
    setMessages((prevMessages: any ) => {
      let newMessages: any  = [...prevMessages];
      newMessages[newMessages.length - 1].message = `${newMessages[newMessages.length - 1].message}${data}`;
      return newMessages;
    });
  }

  function handleQuestion(data: any ) {
    setMessages((prevMessages: any ) => {
      const newMessages: any  = [
        ...prevMessages,
        { message: data.message, who: 'user', spents: {}, messageId: uuid(), threadId: threadIdParam, userId: data.userId, username: data.username, createdAt: data.createdAt } as Message,
        { message: '', who: 'bot', spents: {}, messageId: uuid(), threadId: threadIdParam, userId: undefined, username: undefined, createdAt: Date.now().toString() } as Message,
      ];
      return newMessages;
    });
    setLoading(true);
  }

  function handleCollaboration(data: any ) {
    if (data.userId !== auth.user.uid) {
      if (!data.collaboration) {
        dispatch({ type: "SET_NOTIFICATION", payload: { show: true, title: 'Collaboration', message: `Collaboration turned off, you will no longer be able to collaborate with this thread.`, type: 'warning' } });
        setIsCollaborativeThread(false);
        let channel: any  = pusher.channel(threadIdParam);
        if (channel) {
          channel.unbind('llm-response', handleResponse);
          channel.unbind('llm-question', handleQuestion);
        }
      } else {
        // dispatch({ type: "SET_NOTIFICATION", payload: { show: true, title: 'Collaboration', message: `Collaboration turned on, you will now be able to collaborate with this thread.`, type: 'success' } });
        setIsCollaborativeThread(true);
        let channel: any  = pusher.channel(threadIdParam);
        if (channel) {
          channel.unbind('llm-response', handleResponse);
          channel.unbind('llm-question', handleQuestion);
          channel.unbind('llm-collaboration', handleCollaboration);
          pusher.unsubscribe(threadIdParam);
        }
        channel = pusher.subscribe(threadIdParam);
        channel.bind('llm-response', handleResponse);
        channel.bind('llm-question', handleQuestion);
        channel.bind('llm-collaboration', handleCollaboration);
      }
    }
  }

  const createCollaborativeThread = async (collaborate: any ) => {
    if (collaborate) {
      // add logs
      const response: any  = await updateCollaboration(threadIdParam,selectedChatCategory.category_id,selectedOrganisation._id,collaborate);
      const data: any  = await response?.data;
      if (data.success === 1) {
        setIsCollaborativeThread(collaborate);
        let channel: any  = pusher.channel(threadIdParam);
        if (channel) {
          await channel.unbind('llm-response', handleResponse);
          await channel.unbind('llm-question', handleQuestion);
          await channel.unbind('llm-collaboration', handleCollaboration);
          await pusher.unsubscribe(threadIdParam);
        }
        channel = pusher.subscribe(threadIdParam);
        channel.bind('llm-response', handleResponse);
        channel.bind('llm-question', handleQuestion);
        channel.bind('llm-collaboration', handleCollaboration);
        try {
          navigator.clipboard?.writeText(window.location.href);
        } catch (error) {
          console.error('Error copying to clipboard:', error);
        }
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: 'Collaboration',
            message: 'This thread is set as collaborative. The collaboration link has been successfully copied to your clipboard!',
            type: 'info'
          }
        });
      }
    } else {
      const response: any  = await updateCollaboration(threadIdParam, selectedChatCategory.category_id, selectedOrganisation._id, collaborate);
      const data: any  = await response?.data;
      if (data.success === 1) {
        setIsCollaborativeThread(collaborate);
        const channel: any  = pusher.channel(threadIdParam);
        if (channel) {
          channel.unbind('llm-response', handleResponse);
          channel.unbind('llm-question', handleQuestion);
          channel.unbind('llm-collaboration', handleCollaboration);
          pusher.unsubscribe(threadIdParam);
        }
      }
    }
  };

  const handleDeleteThreadOpen = (threadId: any) => {
    console.log(`Delete thread with ID ${threadId}`);
    setThreadToDelete(threadId);
    setDeleteThreadDialogOpenState(true);
  };

  const handleDeleteThreadClose = () => {
    setDeleteThreadDialogOpenState(false);
  };  

  const handleDeleteThread = async () => {
    // Implement the logic to delete the thread here
    console.log(`Delete thread with ID ${threadToDelete}`);
    const response: any = await deleteThreadService(threadToDelete);
    if (response?.status === 200) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Thread Deletion',
          message: 'Thread deleted successfully!',
          type: 'success'
        }
      });
      updateThreadName(threadToDelete);
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Thread Deletion',
          message: 'Failed to delete thread. Please try again later!',
          type: 'error'
        }
      });
    }

    let threadResponse = getAllThreadsService(categoryIdParam);
    threadResponse.then((res) => {
      res = res.map((thread: any ) => ({
        ...thread,
        created_at: new Date(thread.created_at.$date).getTime()
      }));
      res.sort((a: any , b: any ) => b.created_at - a.created_at);
      setThreads(res);
      if (selectedChatCategory?.category_id === 'GENERAL') {
        dispatch({ type: 'SET_GENERAL_THREADS', payload: res });
      }
    });
    //  route to new thread
    handleCreateNewThread();

    setDeleteThreadDialogOpenState(false);

  };

  const handleEditThread = (threadId: any, currentName: string) => {
    setSelectedEditThreadId(threadId);
    setEditThreadName(currentName);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
  };

    const handleEditDialogSubmit = async () => {
    console.log(`Edit thread with ID ${selectedEditThreadId} to name ${editThreadName}`);
  
    if (editThreadName.trim() === "") {
      setEditDialogError('Thread name cannot be empty.');
      return;
    }
  
    if (editThreadName.trim() === "New Thread") {
      setEditDialogError('Thread name cannot be "New Thread".');
      return;
    }
  
    if (editThreadName.length > 25) {
      setEditDialogError('Thread name cannot exceed 25 characters.');
      return;
    }
  
    const specialCharactersRegex = /[^a-zA-Z0-9\s]/;
    if (specialCharactersRegex.test(editThreadName)) {
      setEditDialogError('Thread name cannot contain special characters.');
      return;
    }
  
    const threadNameUpdateResponse: any = await setCustomThreadName(selectedEditThreadId, editThreadName);
  
    if (threadNameUpdateResponse?.status === 200) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Thread Name Update',
          message: 'Thread name updated successfully!',
          type: 'success'
        }
      });
      updateThreadName(selectedEditThreadId);
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Thread Name Update',
          message: 'Failed to update thread name. Please try again later!',
          type: 'error'
        }
      });
    }
  
    setOpenEditDialog(false);
  };

  const uploadAttachedFiles = async (files: any ) => {
    let fileIds: any  = [];
    const uploadPromises: any  = Array.from(files).map(async (file: any ) => {
      const fileId: any  = uuidv4();
      const fileType: any  = file.name.split('.').slice(-1)[0];
      const { upload_url_status, upload_url }: any  = await get_upload_url_for_thread(state.selectedOrganisation._id, threadIdParam, null, fileType, fileId);
      if (upload_url_status) {
        const response = await fetch(upload_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType || 'application/octet-stream',
          },
        });

        if (!response.ok) {
          throw new Error('File upload failed');
        } else
        {
          fileIds.push({ file_id: fileId, file_type: fileType, file_name: file.name, lastEditedUtc: new Date().toISOString() });
        }
      } else {
        throw new Error('Could not obtain upload URL');
      }
    });

    try {
      await Promise.all(uploadPromises);
      const payload: any  = {
        database_name: 'chat_files',
        organisation_id: state.selectedOrganisation._id,
        is_file_upload: true,
        custom_instructions: [],
        is_google_drive_file: false,
        thread_id: threadIdParam,
        file_ids: fileIds,
      };

      const { status, message }: any  = await createThreadDatabaseFromFileService(payload);
      if (status) {
        console.log('Drive created successfully');
      } else {
        console.error('Error creating drive');
      }
      console.log('All files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const sendMessage = async (message: any , selectedMentions: any , audioBlob: any , attachedFiles: any, hasInternetAccess=false ) => {
    let newMessages: any ;
    if (attachedFiles && attachedFiles.length > 0) {
      setLoading(true);
      await uploadAttachedFiles(attachedFiles);
    }
  
    if (!isCollaborativeThread) {
      newMessages = [
        ...messages,
        { message, who: 'user', spents: {}, messageId: uuid(), threadId: threadIdParam, userId: auth.user.uid, username: auth.user.displayName, createdAt: Date.now().toString() },
        { message: '', who: 'bot', spents: {}, messageId: uuid(), threadId: threadIdParam, userId: undefined, username: undefined, createdAt: Date.now().toString() },
      ];
      setLoading(true);
      setMessages(newMessages);
    }
  
    try {
      let agents: any  = [];
      if (selectedMentions[0]?.id) {
        agents = selectedMentions;
        for (let i = 0; i < selectedMentions.length; i++) {
          message = `${selectedMentions[i]?.display} ${message
          }`;
        }
        
      } else if (selectedMentions?.id && selectedMentions?.display) {
        agents = [selectedMentions];
        message = `${selectedMentions?.display} ${message}`;
      }
      let base64String: any  = null;
      let shouldWait: any  = false;
      if (audioBlob) {
        shouldWait = true;
        const reader: any  = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          base64String = reader.result;
          shouldWait = false;
        };
      }
  
      while (shouldWait) {
        await new Promise((r) => setTimeout(r, 200));
      }
  
      const payload: any  = {
        message,
        category_id: selectedChatCategory.category_id,
        databaseIds: selectedChatCategory.databaseIds,
        organisation_id: selectedOrganisation._id,
        thread_id: threadIdParam,
        agents,
        model: selectedModel,
        is_speech_to_text: !!audioBlob,
        speech_data: base64String,
        message_id: isCollaborativeThread ? uuid() : newMessages[newMessages.length - 2].messageId,
        response_message_id: isCollaborativeThread ? uuid() : newMessages[newMessages.length - 1].messageId,
        value_of_k: k_value || 10,
        has_internet_access: hasInternetAccess
      };
  
      const busyResponse: any  = await checkIfThreadIsBusy(threadIdParam);
      const data: any  = busyResponse?.data;
  
      if (!data.data.busy || !isCollaborativeThread) {
        let response: any ;
        try {
          response = await axiosApiInstance.post('v2/inquire', payload, {
            onDownloadProgress: (progressEvent) => {
              if (!isCollaborativeThread) setLoading(false);
              const xhr = progressEvent.event.target;
              const { responseText } = xhr;
              let responseJson;
              try {
                responseJson = JSON.parse(responseText);
              } catch (error) {
                console.log('Failed to parse responseText as JSON', error);
              }
              if (responseJson && responseJson.data && responseJson.success === 0) {
                console.log("Tokens not available");
              } else {
                if (!isCollaborativeThread) {
                  newMessages[newMessages.length - 1].message = responseText;
                  setMessages([...newMessages]);
                }
              }
            },
          });
          if (response.status === 200) {
            updateThreadName(threadIdParam);
          }
        } catch (error: any ) {
          if (error.response) {
            const status = error.response.status;
            if (status === 403) {
              newMessages[newMessages.length - 1].message += 'It appears there is a problem with your organization\'s subscription. Please contact support.';
            } else if (status === 401) {
              newMessages[newMessages.length - 1].message += 'Looks like your session has expired. Please refresh the page or login again.';
            } else {
              newMessages[newMessages.length - 1].message += 'Something went wrong. Please try again later or contact support!';
            }
            setMessages([...newMessages]);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        }
      } else {
        dispatch({ type: "SET_NOTIFICATION", payload: { show: true, title: 'Thread Busy', message: 'A response is currently being generated for another user. Please wait a moment and try again.', type: 'error' } });
      }
    } catch (error) {
      setLoading(false);
      if (!isCollaborativeThread) {
        newMessages[newMessages.length - 1].message += 'Something went wrong. Please try again!';
        setMessages([...newMessages]);
      }
    }
    if (spentMatrix) setMatrixData();
  };
  
  const formatDate = (date: any ) => {
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 1) {
      return 'Today';
    } else if (diffDays === 2) {
      return 'Yesterday';
    } else if (diffDays <= 7) {
      return `${diffDays - 1} days ago`;
    } else {
      return date.toLocaleDateString();
    }
  };

  const getThreadsByGroup = (threads: any) => {
  const favouriteThreadsSorted = threads.filter((thread: any) => thread.favourite).sort((a: any, b: any) => Number(b.created_at) - Number(a.created_at));
  const nonFavouriteThreadsSorted = threads.filter((thread: any) => !thread.favourite).sort((a: any, b: any) => Number(b.created_at) - Number(a.created_at));

  const groupedThreads = nonFavouriteThreadsSorted.reduce((groups: Record<string, { value: string; label: string }[]>, thread: any) => {
    const date = formatDate(new Date(Number(thread.created_at)));
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push({
      value: thread.thread_id,
      label: thread.thread_name,
    });
    return groups;
  }, {});

  const favouriteThreads = favouriteThreadsSorted.map((thread: any) => ({
    value: thread.thread_id,
    label: thread.thread_name,
  }));

  // Add favorites to return array only if there are any
    if (favouriteThreads.length > 0) {
        return [
        { label: 'Favorites', options: favouriteThreads },
        ...Object.keys(groupedThreads).map((date) => ({
            label: date,
            options: groupedThreads[date],
        })),
        ];
    } else {
        return Object.keys(groupedThreads).map((date) => ({
        label: date,
        options: groupedThreads[date],
        }));
    }
};
  
  const formatGroupLabel = (data: any) => (
    <div>
      <span>{data.label}</span>
    </div>
  );
  
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleThreadDropdownOpen = () => {
    setThreadIsDropdownOpen(true);
  };

  const handleThreadDropdownClose = () => {
    setThreadIsDropdownOpen(false);
  };
  
  const valuetext = (value: any) => {
    setKValue(Number(value));
    return `${value}°C`;
  };

  const handleOrgMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    setAuth({});
    navigate('/auth/login');
  };

  const [openProfileModal, setOpenProfileModal] = useState(false);

  const handleProfile = () => {
    setOpenProfileModal(true);
    
  };

  const handleLeftDrawerOpen = () => {
    setLeftDrawerOpen(true);
  };

  const handleOrgMenuClose = () => {
    setAnchorEl(null);
  };

  const onChangeOrg = (org: any) => {
    console.log('org', org);
    if (org._id !== state.selectedOrganisation._id && threadIdParam && categoryIdParam && orgId) {
      window.location.href = `/organisation/${org._id}/category/1/thread/1`;
    } else {
      dispatch({
        type: 'SET_ACTIVE_ORGANISATION',
        payload: org
      });
    }
  };

  const handleFavoriteThread = async (threadId: any) => {
    const response: any  = await favouriteThreadService(threadId, selectedOrganisation._id, true);
    if (response?.status === 200) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Favorite Thread',
          message: 'Thread added to favorites!',
          type: 'success'
        }
      });
      const updatedThreads = threads.map((thread: any) =>
          thread.thread_id === threadId ? { ...thread, favourite: true } : thread
      );
      setThreads(updatedThreads);
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Favorite Thread',
          message: 'Failed to add thread to favorites. Please try again later!',
          type: 'error'
        }
      });
    }
  }

  const removeThreadFromFavorites = async (threadId: any) => {
    const response: any  = await favouriteThreadService(threadId, selectedOrganisation._id, false);
    if (response?.status === 200) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Mark as favorite',
          message: 'Thread removed from favorites!',
          type: 'success'
        }
      });
      const updatedThreads = threads.map((thread: any) =>
          thread.thread_id === threadId ? { ...thread, favourite: false } : thread
      );
      setThreads(updatedThreads);
    } else {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: 'Remove from favorites',
          message: 'Failed to remove thread from favorites. Please try again later!',
          type: 'error'
        }
      });
    }
  }

  const handleOrganisatioSettings = () => {
    const { role } = state.selectedOrganisation.members.find((m: any) => m.user_id === auth.user.user_id)
    if (role === 'owner' || role === 'admin') {
      navigate('/organisation/settings')
    } else {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: { show: true, title: 'Authorization', message: 'You are not authorized!', type: 'warning' }
      })
      navigate(`/organisation/${state.selectedOrganisation._id}/category/1/thread/1`)
    }
  }
  
  const handleLeftDrawerClose = () => {
    setLeftDrawerOpen(false);
  };

  const handleNewGeneralThread = () => {
    try {
      dispatch({
        type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
        payload: { category_id: 'GENERAL', category_name: 'General' }
      });
      const new_thread_id = uuid();
      const thread = { thread_id: new_thread_id, thread_name: 'New Thread', category_id: 'GENERAL', user_id : auth.user.uid }
      generalThreads.unshift(thread);
      dispatch({
          type: 'SET_GENERAL_THREADS',
          payload: generalThreads
      });
      navigate(`/organisation/${selectedOrganisation._id}/category/GENERAL/thread/${new_thread_id}`);
    } catch (error) {
      console.error(error);
    }
  }

  const drawerWidth = 240;

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Box
      width="100%"
      height="100dvh"
      display="flex"
      flexDirection="column"
      p={2}
      justifyContent="flex-start"
    >
      <Modal
        open={openProfileModal}
        onClose={() => setOpenProfileModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "70%",
          }}
        >
          <Box id="modal-description" sx={{ mt: 2 }}>
            <SettingsPage />
          </Box>
        </Box>
      </Modal>
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <RedirectToNewThread />
        <Box
          width="100%"
          sx={{
            fontFamily: "Roboto, sans-serif",
            animation: "blur 5s infinite",
          }}
          flex="0 0 5%"
        >
          <Box pb={2} display="flex" flexDirection="row" width="100%">
            <Box width="66%">
              <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    overflow: "hidden",
                    maxHeight: "100dvh",
                  },
                }}
                variant="temporary"
                anchor="left"
                open={openLeftDrawer}
                ModalProps={{ onBackdropClick: () => setLeftDrawerOpen(false) }}
              >
                <DrawerHeader>
                  <div className="w-96">
                    <Button
                      id="fade-button"
                      aria-controls={orgOpenMenu ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={orgOpenMenu ? "true" : undefined}
                      onClick={handleOrgMenuClick}
                      fullWidth
                      endIcon={<ExpandMoreIcon />}
                    >
                      {state.selectedOrganisation?.name}
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={orgOpenMenu}
                      onClose={handleOrgMenuClose}
                      TransitionComponent={Fade}
                    >
                      {state.organisations.map((org: any) => (
                        <MenuItem
                          key={org._id}
                          onClick={() => onChangeOrg(org)}
                        >
                          {org?.name}
                        </MenuItem>
                      ))}
                      <Divider sx={{ my: 0.5 }} />
                      <MenuItem onClick={handleOrganisatioSettings}>
                        <SettingsIcon sx={{ pr: 0.5 }} />
                        Organisation & Settings
                      </MenuItem>
                    </Menu>
                  </div>
                  <IconButton onClick={handleLeftDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                {window.location.pathname.includes("/thread/") ? (
                  <ChatCategoryMenu />
                ) : (
                  <AdminMenu />
                )}
              </Drawer>

              {categoryIdParam !== "GENERAL" && !isMobile ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleLeftDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(openLeftDrawer && { display: "none" }) }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {!isMobile && (
                    <div className="flex items-center">
                      <a href={"/"}>
                        <img
                          src={
                            selectedOrganisation?.org_logo
                              ? selectedOrganisation.org_logo
                              : ""
                          }
                          alt="Logo"
                          style={{ maxHeight: "30px" }}
                        />
                      </a>
                    </div>
                  )}
                  <div>
                    <DarkModeToggle />
                  </div>

                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel htmlFor="grouped-select">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        Threads
                        {isCollaborativeThread && (
                          <Tooltip title="Collaborative Thread">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "8px",
                              }}
                            >
                              <GroupIcon
                                sx={{
                                  verticalAlign: "middle",
                                  marginRight: "4px",
                                }}
                              />
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    </InputLabel>
                    <Select
                      key={threads.length}
                      id="grouped-select"
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Threads
                          {isCollaborativeThread && (
                            <Tooltip title="Collaborative Thread">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "8px",
                                }}
                              >
                                <GroupIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    marginRight: "4px",
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      }
                      onOpen={handleThreadDropdownOpen}
                      onClose={handleThreadDropdownClose}
                      onChange={(e: any) => handleThreadSelect(e)}
                      value={selectedThread?.thread_id}
                    >
                      
                      {getThreadsByGroup(threads)?.flatMap(
        (group, groupIndex) => [
          <ListSubheader key={`header-${groupIndex}`}>
            {group.label}
          </ListSubheader>,
          ...group.options.map(
            (option: any, optionIndex: any) => (
              <MenuItem
                key={`${group.label}-${option.value}-${groupIndex}-${optionIndex}`}
                value={option.value}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  {option.label}
                                    {isThreadDropdownOpen && option.label !== "New Thread" && (
                    <Box>
                      {/*Favorite thread*/}

                      <Tooltip title={group.label === "Favorites" ? "Remove thread from favorites" : "Favorite Thread"}>
                        <IconButton
                          edge="end"
                          aria-label={group.label === "Favorites" ? "Remove thread from favorites" : "Favorite Thread"}
                          size="small"
                          onClick={() => group.label === "Favorites" ? removeThreadFromFavorites(option.value) : handleFavoriteThread(option.value)}
                        >
                          {group.label === "Favorites" ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderOutlined fontSize="small" />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Thread">
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          size="small"
                          onClick={() =>
                            handleEditThread(
                              option.value,
                              option.label
                            )
                          }
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Thread">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          size="small"
                          onClick={() =>
                            handleDeleteThreadOpen(option.value)
                          }
                          sx={{ color: theme.palette.error.main }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </MenuItem>
            )
          ),
        ]
      )}
                    </Select>
                  </FormControl>
                  {/* <Select
      onChange={(e: any) => handleThreadSelect(e.value)}
      defaultValue={{ label: selectedThread?.thread_name, value: selectedThread?.thread_id }}
      options={getThreadsByGroup()}
      value={{ label: selectedThread?.thread_name, value: selectedThread?.thread_id }}
    >

      </Select> */}
                  <Tooltip title="Create New Thread" placement="top">
                    <Button
                      onClick={handleCreateNewThread}
                      sx={{ ml: 2, backgroundColor: "#3498db", color: "#fff" }}
                    >
                      <IoMdAdd size={25} />
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleLeftDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(openLeftDrawer && { display: "none" }) }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {/* <div className="flex items-center">
      <a href={'/'}>
        <img src={selectedOrganisation?.org_logo ? selectedOrganisation.org_logo : ''} alt="Logo" style={{ maxHeight: "30px" }} />
      </a>
    </div> */}
                  {categoryIdParam !== "GENERAL" && (
                    <div>
                      <FormControl
                        sx={{ pr: 1, maxWidth: "30vw", minWidth: 100 }}
                      >
                        <InputLabel htmlFor="grouped-select">
                          Threads
                        </InputLabel>
                        <Select
                          key={threads.length}
                          id="grouped-select"
                          label="Threads"
                          onChange={(e: any) => handleThreadSelect(e)}
                          onOpen={handleThreadDropdownOpen}
                          onClose={handleThreadDropdownClose}
                          value={selectedThread?.thread_id}
                          sx={{
                            fontSize: "1rem",
                            height: "36px",
                            ".MuiSelect-select": {
                              paddingTop: "6px",
                              paddingBottom: "6px",
                            },
                          }}
                        >
                          <MenuItem
                            key={`New_Thread`}
                            onClick={handleCreateNewThread}
                          >
                            + New Thread
                          </MenuItem>
                          {getThreadsByGroup(threads)?.flatMap(
                            (group, groupIndex) => [
                              <ListSubheader key={`header-${groupIndex}`}>
                                {group.label}
                              </ListSubheader>,
                              ...group.options.map(
                                (option: any, optionIndex: any) => (
                                   <MenuItem
    sx={{ fontSize: "1rem" }}
    key={`${group.label}-${option.value}-${groupIndex}-${optionIndex}`}
    value={option.value}
>
  <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
    <Typography noWrap sx={{ flexGrow: 1 }}>
      {option.label}
    </Typography>
    { isThreadDropdownOpen && (<Box display="flex" flexDirection="row" justifyContent="flex-end">
      <IconButton
          edge="end"
          aria-label={group.label === "Favorites" ? "Remove thread from favorites" : "Favorite Thread"}
          size="small"
          onClick={() => group.label === "Favorites" ? removeThreadFromFavorites(option.value) : handleFavoriteThread(option.value)}
      >
        {group.label === "Favorites" ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderOutlined fontSize="small" />}
      </IconButton>

      <IconButton
          edge="end"
          aria-label="edit"
          size="small"
          onClick={() => handleEditThread(option.value, option.label)}
      >
        <EditIcon fontSize="small" />
      </IconButton>

      <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={() => handleDeleteThreadOpen(option.value)}
          sx={{ color: theme.palette.error.main }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Box>)}
  </Box>
</MenuItem>
                                )
                              ),
                            ]
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </Box>
              )}
            </Box>
            <Box width="33%" display="flex" justifyContent="flex-end">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={2}
              >
                <Tooltip
                  title="Select the model you want to use for this thread"
                  placement="top"
                >
                  <FormControl>
                    <InputLabel
                      id="model-select-label"
                      sx={{ fontSize: "1rem" }}
                    >
                      Model
                    </InputLabel>
                    <MuiSelect
                      labelId="model-select-label"
                      id="model-select"
                      value={selectedModel}
                      onChange={(e) => setSelectedModel(e.target.value)}
                      label="Model"
                      sx={{
                        fontSize: "1rem",
                        maxWidth: "30vw",
                        minWidth: 100,
                        height: "36px",
                        ".MuiSelect-select": {
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        },
                      }}
                    >
                      <MenuItem value="gpt-4" sx={{ fontSize: "1rem" }}>
                        GPT-4
                      </MenuItem>
                      <MenuItem value="gpt-4o" sx={{ fontSize: "1rem" }}>
                        GPT-4o
                      </MenuItem>
                      <MenuItem value="gpt-4-turbo" sx={{ fontSize: "1rem" }}>
                        GPT-4 Turbo
                      </MenuItem>
                      <MenuItem value="gpt-3.5-turbo" sx={{ fontSize: "1rem" }}>
                        GPT-3.5 Turbo
                      </MenuItem>
                      <MenuItem value="gemini-pro" sx={{ fontSize: "1rem" }}>
                        GEMINI PRO
                      </MenuItem>
                      <MenuItem value="claude-3-opus" sx={{ fontSize: "1rem" }}>
                        CLAUDE 3 OPUS
                      </MenuItem>
                    </MuiSelect>
                  </FormControl>
                </Tooltip>

                <ChatConfiguration
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                  isCollaborativeThread={isCollaborativeThread}
                  createCollaborativeThread={createCollaborativeThread}
                  k_value={k_value}
                  setKValue={setKValue}
                  videoToggleEnabled={!!selectedChatCategory?.videos}
                  collaborationToggleEnabled={
                    (auth.user.uid === selectedThread?.user_id ||
                      selectedThread?.user_id === undefined) &&
                    (selectedThread?.thread_name !== "New Thread" ||
                      messages?.length > 2) &&
                    categoryIdParam !== "GENERAL"
                  }
                  auth={auth}
                  profilePhoto={profilePhoto}
                  handleProfile={handleProfile}
                  handleLogout={handleLogout}
                />
                {!isMobile && (
                  <AccountMenu
                    auth={auth}
                    profilePhoto={profilePhoto}
                    handleProfile={handleProfile}
                    handleLogout={handleLogout}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {isDrawerOpen && (
            <Box className="drawer-dimensions">
              <VideoDrawer
                isOpen={isDrawerOpen}
                onClose={handleDrawerToggle}
                videos={selectedChatCategory?.videos}
              />
            </Box>
          )}
        </Box>
        <Box
          flex={`0 1 85%`}
          overflow="auto"
          sx={{
            overscrollBehavior: "auto",
          }}
          id="message-container-div"
        >
          {isLoadingThread ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box width="100%">
              {messages.map(
                (
                  {
                    message,
                    who,
                    spents,
                    messageId,
                    threadId,
                    userId,
                    username,
                    createdAt,
                  }: any,
                  index: any
                ) => (
                  <ChatLine
                    key={index}
                    who={who}
                    message={message}
                    spents={spents}
                    threadId={threadId}
                    messageId={messageId}
                    userId={userId}
                    username={username}
                    createdAt={createdAt}
                  />
                )
              )}
              {loading && <LoadingChatLine />}
              {messages.length < 2 && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className="mx-auto flex flex-grow text-gray-600 clear-both"
                >
                  Type a message to start the conversation
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box
          flex="0 0 auto"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          // position="fixed"
          bottom={0}
          left={0}
          right={0}
          p={isMobile ? 0 : 2}
          bgcolor="background.paper"
          boxShadow={3}
        >
          <InputMessage
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            selectedChatCategory={selectedChatCategory}
            loading={loading}
            isCollaborativeThread={isCollaborativeThread}
            isThreadOwner={
              auth.user.uid === selectedThread?.user_id ||
              selectedThread?.user_id === undefined
            }
          />
        </Box>
      </Box>

            <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Thread Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new name for the thread.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Thread Name"
            type="text"
            fullWidth
            value={editThreadName}
            onChange={(e) => {
              setEditThreadName(e.target.value);
              setEditDialogError(''); // Clear error message on change
            }}
            error={!!editDialogError}
            helperText={editDialogError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditDialogSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={deleteThreadDialogOpenState}
        onClose={handleDeleteThreadClose}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this thread?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteThreadClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteThread} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}